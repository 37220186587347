import { create } from "zustand";
import { callWebToAppFunction } from "../utils/androidCallers";
import { ENVIRONMENTS, EventTypes, WebToApp } from "../constants/Constants";
import useAdUnitStore from "./useAdUnitStore";
import { EventInfo } from "../types/window";
import { AdFormat, AdPlacement } from "../constants/AdConstants";
import useSessionStore from "./useSessionStore";
import useEnvStore from "./useEnvStore";

type AdState = {
  adLoadAttempt: number;
  maxLoadAttempts: number;
  adLoadAttempts: {
    [key: string]: number;
  };
  isInsAdLoadedStatus: {
    [key: string]: boolean;
  };
  isAllAdLoadedFirstTime: boolean;
  temporaryPlacementFlag: string;
  temporaryPlacementFlag_replay: string;
  totalAdsInCache: number;
  adLoadFailedDueToOffline: boolean;
  adLoadFailedPlacement: string;
  adLoadRetryFunc: () => void;
  incrementTotalAdsInCache: () => void;
  incrementLoadAttempts: (adPlacement: string) => void;
  resetLoadAttempts: (adPlacement: string) => void;
  setInsAdLoaded: (adPlacement: string, loaded: boolean) => void;
  setIsAllAdLoadedFirstTime: (loaded: boolean) => void;
  setInsAdLoadRetryFunc: (
    adClient: string,
    adUnitId: string,
    adPlacement: string,
  ) => void;
  setRdAdLoadRetryFunc: (
    adClient: string,
    adUnitId: string,
    adPlacement: string,
  ) => void;
  loadInsAd: (adClient: string, adUnitId: string, adPlacement: string) => void;
  loadPubInsAd: (adPlacement: string) => void;
  loadInHouseInsAd: (adPlacement: string) => void;
  loadRdAd: (adClient: string, adUnitId: string, adPlacement: string) => void;
  loadPubRdAd: (adPlacement: string) => void;
  loadInHouseRdAd: (adPlacement: string) => void;
  retryLoadingInsAd: (adPlacement: string) => void;
  showPubInsAd: (adPlacement: string) => void;
  showInHouseInsAd: (adPlacement: string) => void;
  showPubRdAd: (adPlacement: string) => void;
  showInHouseRdAd: (adPlacement: string) => void;
  checkInsAdLoaded: (adPlacement: string) => boolean;
  checkInHouseInsAdLoaded: (adPlacement: string) => boolean;
  checkRdAdLoaded: (adPlacement: string) => boolean;
  checkInHouseRdAdLoaded: (adPlacement: string) => boolean;
  setTemporaryPlacementFlag: (flagVal: string) => void;
  setTemporaryPlacementFlag_replay: (flagVal: string) => void;
};
const useAdStore = create<AdState>()((set, get) => ({
  adLoadAttempt: 0,
  maxLoadAttempts: 1,
  adLoadAttempts: {},
  isInsAdLoadedStatus: {},
  isAllAdLoadedFirstTime: false,
  temporaryPlacementFlag: "",
  totalAdsInCache: 0,
  temporaryPlacementFlag_replay: "",
  adLoadFailedDueToOffline: false,
  adLoadFailedPlacement: "",
  adLoadRetryFunc: () => {},
  incrementTotalAdsInCache: () =>
    set((prev) => ({ totalAdsInCache: prev.totalAdsInCache + 1 })),
  incrementLoadAttempts: (adPlacement: string) =>
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: (state.adLoadAttempts[adPlacement] ?? 1) + 1,
      },
    })),
  decrementTotalAdsInCache: () =>
    set((prev) => ({ totalAdsInCache: prev.totalAdsInCache - 1 })),
  resetLoadAttempts: (adPlacement: string) =>
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    })),
  setIsAllAdLoadedFirstTime: (loaded) =>
    set((state) => ({
      isAllAdLoadedFirstTime: loaded,
    })),
  setInsAdLoaded: (adPlacement, loaded) =>
    set((state) => ({
      isInsAdLoadedStatus: {
        ...state.isInsAdLoadedStatus,
        [adPlacement]: loaded,
      },
    })),
  setInsAdLoadRetryFunc: (adClient, adUnitId, adPlacement) =>
    set((prev) => ({
      adLoadFailedDueToOffline: true,
      adLoadFailedPlacement: adPlacement,
      adLoadRetryFunc: () => {
        set({ adLoadFailedDueToOffline: false });
        prev.loadInsAd(adClient, adUnitId, adPlacement);
      },
    })),
  setRdAdLoadRetryFunc: (adClient, adUnitId, adPlacement) =>
    set((prev) => ({
      adLoadFailedDueToOffline: true,
      adLoadFailedPlacement: adPlacement,
      adLoadRetryFunc: () => {
        set({ adLoadFailedDueToOffline: false });
        prev.loadRdAd(adClient, adUnitId, adPlacement);
      },
    })),
  loadInsAd: (adClient: string, adUnitId: string, adPlacement: string) => {
    const game = useSessionStore.getState().currentGame;
    if (
      useEnvStore.getState().env.toLowerCase() == ENVIRONMENTS.dev ||
      useEnvStore.getState().env.toLowerCase() == ENVIRONMENTS.staging
    ) {
      const onInsAdSuccessEvent: EventInfo = {
        eventType: EventTypes.ad_load,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          ad_type: AdFormat.Interstitial,
          game_name: game?.name,
          game_id: game?.id,
          placement: adPlacement,
          mediation: adClient,
          game_session_id: useSessionStore.getState().gameSessionId,
          ad_unit: adUnitId,
        },
      };
      // console.log("On ins ad loaded successfuly line 3");
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        onInsAdSuccessEvent,
        null,
        undefined,
      );
    }
    callWebToAppFunction(WebToApp.LOAD_INS_AD, adClient, adUnitId, adPlacement);
  },
  loadPubInsAd: (adPlacement) => {
    const adUnitLoadInsAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;

    console.log(
      `webToAdCall calling load Ins ad for publisher inside useAdStore; placement: ${adPlacement}, adUnit: ${adUnitLoadInsAd.adUnitId} `,
    );

    get().loadInsAd(
      adUnitLoadInsAd.adClient,
      adUnitLoadInsAd.adUnitId,
      adUnitLoadInsAd.placementName,
    );
  },
  loadInHouseInsAd: (adPlacement) => {
    const adUnitLoadInsAd = useAdUnitStore
      .getState()
      .getInHouseAdUnitByPlacement(adPlacement)!;

    console.log(
      `webToAdCall calling load Ins ad for in house inside useAdStore; placement: ${adPlacement}, adUnit: ${adUnitLoadInsAd.adUnitId} `,
    );

    get().loadInsAd(
      adUnitLoadInsAd.adClient,
      adUnitLoadInsAd.adUnitId,
      adUnitLoadInsAd.placementName,
    );
  },
  loadRdAd: (adClient: string, adUnitId: string, adPlacement: string) => {
    const game = useSessionStore.getState().currentGame;
    if (
      useEnvStore.getState().env.toLowerCase() == ENVIRONMENTS.dev ||
      useEnvStore.getState().env.toLowerCase() == ENVIRONMENTS.staging
    ) {
      const onInsAdSuccessEvent: EventInfo = {
        eventType: EventTypes.ad_load,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: useEnvStore.getState().isOnline ? "online" : "offline",
          ad_type: AdFormat.Rewarded,
          game_name: game?.name,
          game_id: game?.id,
          placement: adPlacement,
          mediation: adClient,
          game_session_id: useSessionStore.getState().gameSessionId,
          ad_unit: adUnitId,
        },
      };
      // console.log("On ins ad loaded successfuly line 3");
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        onInsAdSuccessEvent,
        null,
        undefined,
      );
    }
    callWebToAppFunction(WebToApp.LOAD_RD_AD, adClient, adUnitId, adPlacement);
  },
  loadPubRdAd: (adPlacement) => {
    console.log(
      "webToAdCall calling load Rd ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitLoadRdAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;

    get().loadRdAd(
      adUnitLoadRdAd.adClient,
      adUnitLoadRdAd.adUnitId,
      adUnitLoadRdAd.placementName,
    );
  },
  loadInHouseRdAd: (adPlacement) => {
    console.log(
      "webToAdCall calling load Rd ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitLoadRdAd = useAdUnitStore
      .getState()
      .getInHouseAdUnitByPlacement(adPlacement)!;

    get().loadRdAd(
      adUnitLoadRdAd.adClient,
      adUnitLoadRdAd.adUnitId,
      adUnitLoadRdAd.placementName,
    );
  },
  retryLoadingInsAd: (adPlacement) => {
    // const { maxLoadAttempts, loadInsAd, incrementLoadAttempts } = get();
    // if (get().adLoadAttempt < maxLoadAttempts) {
    //   console.log("Ins ad retry attempt:", get().adLoadAttempt);
    //   // const adUnitGameStart = useAdUnitStore
    //   //   .getState()
    //   //   .getAdUnitByPlacement(adPlacement);
    //   loadInsAd(adPlacement);
    //   incrementLoadAttempts();
    // } else console.log("Ins ad retry has failed for ins ad:");
  },
  showPubInsAd: (adPlacement) => {
    console.log(
      "webToAdCall calling SHOW Ins ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitShowInsAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;
    // callWebToAppFunction(
    //   WebToApp.SHOW_INS_AD,
    //   AdClients.AppLovin,
    //   AdUnitIds.AppLovin.game_start_fsi,
    //   AdPlacement.game_start_fsi
    // );
    // console.log(
    //   "calling show Ins ad inside useAdStore",
    //   adPlacement,
    //   adUnitShowInsAd?.adClient,
    //   adUnitShowInsAd?.adUnitId
    // );
    callWebToAppFunction(
      WebToApp.SHOW_INS_AD,
      adUnitShowInsAd?.adClient,
      adUnitShowInsAd?.adUnitId,
      adPlacement,
    );
  },
  showInHouseInsAd: (adPlacement) => {
    console.log(
      "webToAdCall calling SHOW Ins ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitShowInsAd = useAdUnitStore
      .getState()
      .getInHouseAdUnitByPlacement(adPlacement)!;
    // callWebToAppFunction(
    //   WebToApp.SHOW_INS_AD,
    //   AdClients.AppLovin,
    //   AdUnitIds.AppLovin.game_start_fsi,
    //   AdPlacement.game_start_fsi
    // );
    // console.log(
    //   "calling show Ins ad inside useAdStore",
    //   adPlacement,
    //   adUnitShowInsAd?.adClient,
    //   adUnitShowInsAd?.adUnitId
    // );
    callWebToAppFunction(
      WebToApp.SHOW_INS_AD,
      adUnitShowInsAd?.adClient,
      adUnitShowInsAd?.adUnitId,
      adPlacement,
    );
  },
  showPubRdAd: (adPlacement) => {
    console.log(
      "webToAdCall calling SHOW Rd ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitShowRdAd = useAdUnitStore
      .getState()
      .getAdUnitByPlacement(adPlacement)!;
    // callWebToAppFunction(
    //   WebToApp.SHOW_INS_AD,
    //   AdClients.AppLovin,
    //   AdUnitIds.AppLovin.game_start_fsi,
    //   AdPlacement.game_start_fsi
    // );
    // console.log(
    //   "calling show Ins ad inside useAdStore",
    //   adPlacement,
    //   adUnitShowInsAd?.adClient,
    //   adUnitShowInsAd?.adUnitId
    // );
    callWebToAppFunction(
      WebToApp.SHOW_RD_AD,
      adUnitShowRdAd.adClient,
      adUnitShowRdAd.adUnitId,
      adPlacement,
    );
  },
  showInHouseRdAd: (adPlacement) => {
    console.log(
      "webToAdCall calling SHOW Rd ad inside useAdStore: ",
      adPlacement,
    );
    const adUnitShowRdAd = useAdUnitStore
      .getState()
      .getInHouseAdUnitByPlacement(adPlacement)!;
    // callWebToAppFunction(
    //   WebToApp.SHOW_INS_AD,
    //   AdClients.AppLovin,
    //   AdUnitIds.AppLovin.game_start_fsi,
    //   AdPlacement.game_start_fsi
    // );
    // console.log(
    //   "calling show Ins ad inside useAdStore",
    //   adPlacement,
    //   adUnitShowInsAd?.adClient,
    //   adUnitShowInsAd?.adUnitId
    // );
    callWebToAppFunction(
      WebToApp.SHOW_RD_AD,
      adUnitShowRdAd.adClient,
      adUnitShowRdAd.adUnitId,
      adPlacement,
    );
  },
  checkInsAdLoaded: (adPlacement) => {
    // console.log("**inside checkInsAdLoaded in useAdStore**");
    const adUnit = useAdUnitStore.getState().getAdUnitByPlacement(adPlacement)!;
    // return callWebToAppFunction(
    //   WebToApp.IS_INS_AD_LOADED,
    //   adUnit.adClient,
    //   adUnit.adUnitId,
    //   AdPlacement.game_start_fsi,
    // );
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    }));
    console.log(
      "webToAdCall checkInsAdLoaded for adClient",
      adUnit.adClient,
      "adUnitId",
      adUnit.adUnitId,
      "adPlacement",
      adPlacement,
    );
    return callWebToAppFunction(
      WebToApp.IS_INS_AD_LOADED,
      adUnit.adClient,
      adUnit.adUnitId,
      adPlacement,
    ) as boolean;
  },
  checkInHouseInsAdLoaded: (adPlacement) => {
    // console.log("**inside checkInsAdLoaded in useAdStore**");
    const adUnit = useAdUnitStore
      .getState()
      .getInHouseAdUnitByPlacement(adPlacement)!;
    // return callWebToAppFunction(
    //   WebToApp.IS_INS_AD_LOADED,
    //   adUnit.adClient,
    //   adUnit.adUnitId,
    //   AdPlacement.game_start_fsi,
    // );
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    }));
    console.log(
      "webToAdCall checkInsAdLoaded for adClient",
      adUnit?.adClient,
      "adUnitId",
      adUnit?.adUnitId,
      "adPlacement",
      adPlacement,
    );
    let isInsAdLoadedCallbackValue = false;
    try {
      isInsAdLoadedCallbackValue = callWebToAppFunction(
        WebToApp.IS_INS_AD_LOADED,
        adUnit?.adClient || "",
        adUnit?.adUnitId || "",
        adPlacement,
      ) as boolean;
      return isInsAdLoadedCallbackValue;
    } catch (error) {
      console.log("error in checkInHouseInsAdLoaded:", error);
      return false;
    }
  },
  checkRdAdLoaded: (adPlacement) => {
    // console.log("**inside checkInsAdLoaded in useAdStore**");
    const adUnit = useAdUnitStore.getState().getAdUnitByPlacement(adPlacement)!;
    // return callWebToAppFunction(
    //   WebToApp.IS_INS_AD_LOADED,
    //   adUnit.adClient,
    //   adUnit.adUnitId,
    //   AdPlacement.game_start_fsi,
    // );
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    }));
    console.log(
      "webToAdCall checkRdAdLoaded for adClient",
      adUnit.adClient,
      "adUnitId",
      adUnit.adUnitId,
      "adPlacement",
      adPlacement,
    );
    return callWebToAppFunction(
      WebToApp.IS_RD_AD_LOADED,
      adUnit.adClient,
      adUnit.adUnitId,
      adPlacement,
    ) as boolean;
  },
  checkInHouseRdAdLoaded: (adPlacement) => {
    // console.log("**inside checkInsAdLoaded in useAdStore**");
    const adUnit = useAdUnitStore
      .getState()
      .getInHouseAdUnitByPlacement(adPlacement)!;
    // return callWebToAppFunction(
    //   WebToApp.IS_INS_AD_LOADED,
    //   adUnit.adClient,
    //   adUnit.adUnitId,
    //   AdPlacement.game_start_fsi,
    // );
    set((state) => ({
      adLoadAttempts: {
        ...state.adLoadAttempts,
        [adPlacement]: 1,
      },
    }));
    console.log(
      "webToAdCall checkRdAdLoaded for adClient",
      adUnit.adClient,
      "adUnitId",
      adUnit.adUnitId,
      "adPlacement",
      adPlacement,
    );
    return callWebToAppFunction(
      WebToApp.IS_RD_AD_LOADED,
      adUnit.adClient,
      adUnit.adUnitId,
      adPlacement,
    ) as boolean;
  },
  setTemporaryPlacementFlag: (flagVal) =>
    set({ temporaryPlacementFlag: flagVal }),
  setTemporaryPlacementFlag_replay: (flagVal) =>
    set({ temporaryPlacementFlag_replay: flagVal }),
}));

export default useAdStore;
