import { AdFormat, AdPlacement } from "../constants/AdConstants";
import useAdStore from "../store/useAdStore";
import useAdUnitStore from "../store/useAdUnitStore";
import { AdUnit } from "../types/AdUnit";
export const AdTypes = {
  inhouse: "inhouse",
  publisher: "publisher",
} as const;
interface AdPlacementQueueElement {
  placementName: string;
  status: string;
  adFormat: string;
  adUnitId: string;
}
export const AdPlacementQueueStatus = {
  LOADING: "loading",
  LOADED: "loaded",
  NA: "NA",
} as const;
type AdType = (typeof AdTypes)[keyof typeof AdTypes];
type AdPlacementQueueStatusTypes =
  (typeof AdPlacementQueueStatus)[keyof typeof AdPlacementQueueStatus];
export class AdsManager {
  private static adsManagerInstances: Map<AdType, AdsManager> = new Map();
  private adPlacementQueue: { [key: string]: AdPlacementQueueElement } = {};
  private adType: AdType;
  private queueIndex = 0;

  static getInstance(adType: AdType): AdsManager {
    if (!AdsManager.adsManagerInstances.has(adType)) {
      JSON.stringify(
        AdsManager.adsManagerInstances.set(adType, new AdsManager(adType)),
      );
    }
    return AdsManager.adsManagerInstances.get(adType)!;
  }
  constructor(adType: AdType = AdTypes.inhouse) {
    this.adType = adType;
  }
  initializeQueue() {
    let allAdUnits: AdUnit[] = [];
    const adPlacements = [
      AdPlacement.game_start_fsi,
      AdPlacement.game_exit_fsi,
      AdPlacement.game_replay_fsi,
      AdPlacement.game_end_fsi,
      AdPlacement.game_play_fsr,
      AdPlacement.game_pause_fsi,
    ];
    if (this.adType == AdTypes.publisher) {
      allAdUnits = useAdUnitStore.getState().adUnits;
    } else if (this.adType == AdTypes.inhouse) {
      allAdUnits = useAdUnitStore.getState().inHouseAdUnits ?? [];
    }
    adPlacements.forEach((placement) => {
      const filteredAdUnit = allAdUnits.find((currentAdUnit) => {
        return placement == currentAdUnit.placementName;
      });
      if (filteredAdUnit) {
        this.adPlacementQueue[placement] = {
          placementName: placement,
          adFormat: filteredAdUnit?.iabadFormat,
          adUnitId: filteredAdUnit?.adUnitId,
          status: AdPlacementQueueStatus.NA,
        };
      }
    });
  }

  getNextPlacement = (): AdPlacementQueueElement | null => {
    const keys = Object.keys(this.adPlacementQueue);
    if (this.queueIndex >= keys.length) return null;
    const nextKey = keys[this.queueIndex];
    const nextPlacement = this.adPlacementQueue[nextKey];
    this.queueIndex += 1;
    return nextPlacement;
  };

  public getQueue = (): { [key: string]: AdPlacementQueueElement } => {
    return this.adPlacementQueue;
  };

  loadNext() {
    const nextPlacement = this.getNextPlacement();
    if (nextPlacement) {
      if (this.adType == AdTypes.inhouse) {
        if (nextPlacement.adFormat == AdFormat.Interstitial) {
          this.adPlacementQueue[nextPlacement.placementName].status =
            AdPlacementQueueStatus.LOADING;
          useAdStore.getState().loadInHouseInsAd(nextPlacement.placementName);
        } else if (nextPlacement.adFormat == AdFormat.Rewarded) {
          this.adPlacementQueue[nextPlacement.placementName].status =
            AdPlacementQueueStatus.LOADING;
          useAdStore.getState().loadInHouseRdAd(nextPlacement.placementName);
        }
      } else if (this.adType == AdTypes.publisher) {
        if (nextPlacement.adFormat == AdFormat.Interstitial) {
          this.adPlacementQueue[nextPlacement.placementName].status =
            AdPlacementQueueStatus.LOADING;
          useAdStore.getState().loadPubInsAd(nextPlacement.placementName);
        } else if (nextPlacement.adFormat == AdFormat.Rewarded) {
          this.adPlacementQueue[nextPlacement.placementName].status =
            AdPlacementQueueStatus.LOADING;
          useAdStore.getState().loadPubRdAd(nextPlacement.placementName);
        }
      }
    } else {
      console.log(
        `adsManager: All ${
          this.adType
        } ads have been loaded and the final queue is: ${JSON.stringify(
          this.adPlacementQueue,
        )}`,
      );
    }
  }
  public handleAdLoad = (
    placement: string,
    status: AdPlacementQueueStatusTypes,
  ) => {
    if (
      placement === this.getQueue()[placement]?.placementName &&
      this.getQueue()[placement]?.status == AdPlacementQueueStatus.LOADING
    ) {
      this.getQueue()[placement].status = status;
      this.loadNext();
    }
  };
}
